// Here you can add other styles
.card-header{
  border-bottom: none;
}

.sidebar_custom_color{
  background: #2E2E2E 0% 0% no-repeat padding-box;
}

a.c-sidebar-nav-link{
  font: Regular 18px/22px Proxima Nova;
  letter-spacing: 0px;
  color: #6C6B6B;
}

a.c-sidebar-nav-link.c-active{
  color:white !important;
  font-weight: bold;
}

.additionalServiceDetails{
  display: inline-block;
  width: 300px;
  text-align: left;
}

.card-body .rotate {
  z-index: 8;
  float: right;
  height: 100%;
}

.card-body .rotate i {
  color: rgba(20, 20, 20, 0.15);
  position: absolute;
  left: 0;
  left: auto;
  right: -10px;
  bottom: 0;
  display: block;
  -webkit-transform: rotate(-44deg);
  -moz-transform: rotate(-44deg);
  -o-transform: rotate(-44deg);
  -ms-transform: rotate(-44deg);
  transform: rotate(-44deg);
}

.wcustom-80{
  width:80px;
}

.td-column-align {
  text-align: right;
}

@media screen and (max-width: 1841px) {
  .small-screen {
    margin-left: 0px !important;
  }
}

.btn-primary {
  background-color: #4b39e8 !important;
  border-color: #4b39e8 !important;
}

.btn-primary:hover {
  background-color: #2a1ab9 !important;
  border-color: #2819ae !important;
}

.page-item.active {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-color: #4b39e8;
  border-color: #4b39e8;
}

.page-item.active {
  .page-link:hover {
    // border-top-left-radius: 0.25rem;
    // border-bottom-left-radius: 0.25rem;
    background-color: #2a1ab9 !important;
    border-color: #2819ae !important;
  }
}

.react-datepicker {
  width: 328px !important;
}

.c-sidebar-nav-link {
  font-size: 0.775rem;
}